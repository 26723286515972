import React, { useEffect, useState } from 'react'
import * as API from '../../../api'
import { useLocation } from 'react-router-dom'
import { checkCreative } from './utils'
import emptyImage from '../../../assets/images/empark.jpg'
import ComponentStyled from './styled'
import { URL_ATTRIBUTE } from '../../../config/api'

const REFETCH_SECONDS = 120000
const MAIN_INTERVAL_SECONDS = 5000

const Home = () => {
  const [activeCreativities, setActiveCreativities] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [position, setPosition] = useState(-1)
  const location = useLocation()
  const params = new URLSearchParams(location?.search)
  const parking = params.get('parking')
  const orientation = params.get('orientation')
  const urlAttribute = params.get('urlAttribute') ? params.get('urlAttribute') : URL_ATTRIBUTE

  useEffect(() => {
    async function fetchCampaigns() {
      let formatCreativities
      try {
        setIsFetching(true)
        const response = await API.getParkingCampaigns(parking)
        localStorage.clear(parking)
        localStorage.setItem(parking, JSON.stringify(response))
        formatCreativities = checkCreative(response?.data)
      } catch {
        const cachedData = JSON.parse(localStorage.getItem(parking))
        formatCreativities = checkCreative(cachedData?.data)
      } finally {
        setIsFetching(false)
      }

      setActiveCreativities(formatCreativities)
      if (position < 0) setPosition(0)
    }

    if (parking) {
      fetchCampaigns()
    }

    const timer = setInterval(() => {
      fetchCampaigns()
    }, REFETCH_SECONDS)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    let timer = null
    if (position > -1 && parking && !isFetching) {
      const creativity = activeCreativities[position]
      const duration = creativity?.duration > 0 ? creativity?.duration * 1000 : MAIN_INTERVAL_SECONDS
      timer = setInterval(() => {
        return setPosition(prevState => {
          let newState = 0
          if (prevState !== activeCreativities?.length - 1) newState = prevState + 1
          return newState
        })
      }, duration)
    } else {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [position, isFetching])

  const getVideoUrlAndMime = creativitie => {
    if (!creativitie || !creativitie[urlAttribute]) {
      return { url: null, mime: null }
    }
    let result = { url: creativitie[urlAttribute] }
    const type = creativitie?.type === 2 ? 'video' : 'image'
    switch (urlAttribute) {
      case 'url':
        result.mime = type + '/' + creativitie.extension
        break
      case 'secondUrl':
        result.mime = type + '/' + creativitie.secondExtension
        break
      case 'thirdUrl':
        result.mime = type + '/' + creativitie.thirdExtension
        break
    }
    return result
  }

  const showCreativities = () => {
    const creativitie = activeCreativities[position]
    const mediaData = getVideoUrlAndMime(creativitie)
    if (creativitie?.type === 2) {
      return (
        <video key={mediaData.url} autoPlay muted loop>
          <source src={mediaData.url} type={mediaData.mime} />
          Your browser does not support the video tag.
        </video>
      )
    }
    return <img key={mediaData.url} src={mediaData.url} alt='Banner' />
  }

  return (
    <ComponentStyled>
      <div className={`creativitie-wrapper ${orientation}`}>
        {activeCreativities?.length > 0 ? showCreativities() : <img src={emptyImage} alt='Banner' />}
      </div>
    </ComponentStyled>
  )
}

export default Home
